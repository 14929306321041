import { TRANSACTION_TYPES } from '@/utils/constants';

const impactsRoutes = [
    {
        path: '/risks',
        name: 'RisksPage',
        component: () => import('../views/risk/RiskComponent.vue'),
        props: { type: TRANSACTION_TYPES.CREATE },
        meta: {
            requiresAuth: true,
            roles: ['ADMIN'],
        },
    },
    {
        path: '/risks/recognition',
        name: 'RisksRecognitionPage',
        component: () => import('../views/risk/RiskRecognitionComponent.vue'),
        props: { type: TRANSACTION_TYPES.CREATE },
        meta: {
            requiresAuth: true,
            roles: ['ADMIN'],
        },
    },
    {
        path: '/risks/management',
        name: 'RisksManagementPage',
        component: () => import('../views/risk/RiskManagementComponent.vue'),
        props: { type: TRANSACTION_TYPES.CREATE },
        meta: {
            requiresAuth: true,
            roles: ['ADMIN'],
        },
    },
    {
        path: '/risks/treatments',
        name: 'TreatmentPlanPage',
        component: () => import('../views/risk/treatments/TreatmentPlanComponent.vue'),
        props: { type: TRANSACTION_TYPES.CREATE },
        meta: {
            requiresAuth: true,
            roles: ['ADMIN'],
        },
    },
    {
        path: '/risks/controls-following',
        name: 'ControlsFollowingPage',
        component: () => import('../views/risk/following/ControlsFollowingComponent.vue'),
        props: { type: TRANSACTION_TYPES.CREATE },
        meta: {
            requiresAuth: true,
            roles: ['ADMIN'],
        },
    }
];

export default impactsRoutes;