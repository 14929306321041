import { TRANSACTION_TYPES } from '../utils/constants';

const assetsRoutes = [


    {
        path: '/assets',
        name: 'AssetsPage',
        component: () => import('@/views/assets/AssetsComponent.vue'),
        meta: { requiresAuth: true }

    },

    {
        path: '/assets/criticity/:assetId',
        name: 'AssetCriticalityPage',
        component: () => import('@/views/assets/AssetCriticalityComponent.vue'),
        props: route => ({ assetId: route.params.assetId }),
        meta: {
            requiresAuth: true,
            roles: ['ADMIN'],
        }

    },

    {
        path: '/assets/create',
        name: 'AssetsCreatePage',
        component: () => import('@/views/assets/AssetsManagerComponent.vue'),
        props: { type: TRANSACTION_TYPES.CREATE },
        meta: { requiresAuth: true }
    },

    {
        path: '/assets/criticity/:assetId',
        name: 'AssetCriticalityPage',
        component: () => import('@/views/assets/AssetCriticalityComponent.vue'),
        meta: {
            requiresAuth: true,
            roles: ['ADMIN'],
        },
        // props: true,
        props: route => ({ assetId: route.params.assetId }),

    },

    {
        path: '/assets/edit/:assetId',
        name: 'AssetsEditPage',
        component: () => import('@/views/assets/AssetsManagerComponent.vue'),
        props: route => ({ type: TRANSACTION_TYPES.EDIT, assetId: route.params.assetId }),
        meta: { requiresAuth: true }
    },

    {
        path: '/assets/show/:assetId',
        name: 'AssetsShowPage',
        component: () => import('@/views/assets/AssetsManagerComponent.vue'),
        props: route => ({ type: TRANSACTION_TYPES.SHOW, assetId: route.params.assetId }),
        meta: { requiresAuth: true }
    },
    {
        path: '/assets/store/from-template',
        name: 'StoreAssetsFromTemplate',
        component: () => import('@/views/assets/AssetsFromTemplateComponent.vue'),
        meta: {
            requiresAuth: true,
            roles: ['ADMIN'],
        },

    }
];

export default assetsRoutes;