export default {

    impacts: 'Impacts',
    impactFactors: 'Impact Factors',
    impactFactor: 'Impact Factor',
    impactMeasures: 'Impact Measures',
    saveMeasures: 'Save Measures',
    addImpactFactor: 'Add Impact Factor',
    savingImpactMeasures: 'Saving Impact Measures',
    impactMeasuresSavedSuccessfully: 'Impact measures saved successfully',
    loadingImpactMeasures: 'Loading impact measures...',
    savingImpactFactor: 'Saving impact factor',
    theImpactFactor: 'the impact factor!',
    errorDeletingImpactFactor: 'An error occurred while deleting the impact factor!',
    impactFactorDeletedSuccessfully: 'Impact factor deleted successfully!',
    impact: 'Impact',
    impactLevel: 'Impact Level',

};
