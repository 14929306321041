export default {
    addCompany: 'Add company',
    company: 'Company',
    companies: 'Companies',
    companyPlaceHolder: 'Select a company',
    noCompany: 'Company not found',
    noCompanies: 'Companies not found',
    legalName: 'Brand and legal Name',
    plan: 'Plan',
    planPlaceHolder: 'Select a plan',
    noPlan: 'Plan not found',
    noPlans: 'Plans not found',
    brand: 'Brand',
    businessActivity: 'Economic activity',
    activityPlaceHolder: 'Select an economic activity',
    noActivity: 'Economic activity not found',
    noActivities: 'Economic activities not found',
    companiesError: 'An error occurred while fetching companies: ',
    confirmDeleteCompany: 'Are you sure you want to delete company?',
    deleteCompanyError: 'An error occurred while deleting company',
    companyDeletedSuccess: 'Company deleted successfully',
    typeCompanyPlaceHolder: 'Select a company type',
    addOrCreateUser: 'To continue, you must add at least one existing user or create a new one.',
    unknown: 'Unknown',
    companyQueryError: 'An error occurred while querying company: ',
    companyCreatedSuccess: 'Company created successfully',
    companyCreationError: 'An error occurred while creating company: ',
    companyUpdatedSuccess: 'Company updated successfully',
    companyUpdateError: 'An error occurred while updating company: ',
    companyCategory: 'Company category',
    companyType: 'Company type',
    companyCreate: 'Create company',
    companySelect: 'Select a company',
    createCompany: 'Create or choosee a company to start',
};
