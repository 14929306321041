export default {
    rol: 'Role',
    roles: 'Roles',
    newRol: 'Add new role',
    userRoles: 'User Roles',
    rolName: 'Role name',
    rolNamePlaceHolder: 'Enter role name',
    rolDescriptionPlaceHolder: 'Enter role description',
    rolPermissions: 'Permissions',
    rolPermission: 'Permission',
    rolPlaceHolder: 'Select a role',
    noRol: 'Role not found',
    noRoles: 'Roles not found',
    rolesError: 'An error occurred while fetching roles:',
    deleteRoleError: 'An error occurred while deleting role',
    roleDeletedSuccess: 'Role deleted successfully',
    roleError: 'An error occurred while fetching role: ',
    roleUpdated: 'Role updated successfully!',
    roleCreated: 'Role created successfully!',
    roleUpdateError: 'Error updating role!',
    roleCreateError: 'Error creating role!',
};
