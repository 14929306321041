export const TRANSACTION_TYPES = {

    CREATE : 'C',
    EDIT: 'E',
    SHOW: 'S',

};

export const ROLE_IDS = {
    1: 'SUPERADMIN',
    2: 'ADMIN',
    3: 'MEMBER',
    4: 'GUEST',
};

export const RISK_ZONES_STYLES = {

    RANGE: [
        { id: 4, style: 'bg-green-100 text-green-800' },
        { id: 3, style: 'bg-yellow-100 text-yellow-800' },
        { id: 2, style: 'bg-orange-100 text-orange-800' },
        { id: 1, style: 'bg-red-100 text-red-800' },
    ]

};

export const CONTROLS_STATUSES = [

    { id: 1, status: { es: 'Activo', en: 'Active'} },
    { id: 6, status: { es: 'Implementado', en: 'Implemented'} }

];

export const STATUSES_IDS = {
    ACTIVE: 1,
    INACTIVE: 2,
    INVITED: 3,
    PENDING: 4,
    FINISH: 5,
    IMPLEMENTED: 6,
    MITIGATED: 7,
};

