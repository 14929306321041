import { createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from '../stores/authStore';
import companiesRoutes from './companies';
import authRoutes from './auth';
import threatsRoutes from './threats';
import assetsRoutes from './assets';
import usersRoutes from './users';
import rolesRoutes from './roles';
import scopesRoutes from './scopes';
import vulnerabilitiesRoutes from './vulnerabilities';
import impactsRoutes from './impacts';
import risks from './risks';
import { ROLE_IDS } from '@/utils/constants';
import probabilityMeasureRoutes from './probabilityMeasureTypes';
import parametersRoutes from './parameters';

const routes = [

    {
        path: '/',
        name: 'LandingPage',
        component: () => import('../views/auth/LoginComponent.vue'),
    },

    {
        path: '/home',
        name: 'HomePage',
        component: () => import('../views/HomePage.vue'),
    },

    {
        path: '/select-company',
        name: 'SelectCompanyPage',
        component: () => import('../views/auth/SelectCompanyComponent.vue'),

    },

    {
        path: '/403',
        name: 'InsufficientPermissionsPage',
        component: () => import('../views/auth/InsufficientPermissionsComponent.vue'),
    },

    ...companiesRoutes,
    ...authRoutes,
    ...threatsRoutes,
    ...assetsRoutes,
    ...usersRoutes,
    ...rolesRoutes,
    ...scopesRoutes,
    ...vulnerabilitiesRoutes,
    ...impactsRoutes,
    ...risks,
    ...probabilityMeasureRoutes,
    ...parametersRoutes,

];

const router = createRouter({

    history: createWebHistory(process.env.BASE_URL),
    routes

});

router.beforeEach((to, from, next) => {

    const authStore = useAuthStore();

    if (to.meta.requiresAuth) {

        if (!authStore.accessToken) {

            return next({ name: 'LandingPage' });
        }

        const userRole = ROLE_IDS[authStore.role.id];

        if (to.meta.roles && !to.meta.roles.includes(userRole)) {

            console.log("Mapped userRole:", userRole);
            console.log("Expected roles:", to.meta.roles);
            return next({ name: 'InsufficientPermissionsPage' });

        }

        if (!authStore.scope?.id && to.name !== 'ScopesSelectPage') {
            return next({ name: 'ScopesSelectPage' });
        }

    }

    next();

});

export default router;