export default {

    newAsset: 'Agregar Activo',
    relatedAssets: 'Activos Relacionados',
    assets: 'Activos',
    assetCategory: 'Categoría de Activo',
    noAssetCategory: 'Sin Categoría',
    noAssetsCategories: 'No hay Categorías de Activos',

};
