const parametersRoutes = [
    {
        path: '/parameters',
        name: 'ParametersPage',
        component: () => import('../views/parameters/ParametersComponent.vue'),
        meta: {
            requiresAuth: true,
            roles: ['ADMIN'],
        },
    },
];

export default parametersRoutes;