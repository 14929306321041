
const probabilityMeasureRoutes = [
    {
        path: '/probability-measure',
        name: 'ProbabilityMeasurePage',
        component: () => import('../views/probabilityMeasureTypes/ProbabilityMeasureTypesComponent.vue'),
        meta: {
            requiresAuth: true,
            roles: ['ADMIN'],
        },
    },

];

export default probabilityMeasureRoutes;