
export default {

    parameters: 'Parámetros y datos',
    tableType: 'Tabla',
    noOptionsTableTypes: 'No existen las tablas',
    noOptionsTableType: 'No exite la tabla',
    editLoadingParameters: 'Editando parámetros',
    createLoadingParameters: 'Creando parámetros',
    editParametersSuccess: 'Parámetros editados con éxito',
    createParametersSuccess: 'Parámetros creados con éxito',
    editParameterError: 'Error al editar parámetros',
    createParameterError: 'Error al crear parámetros',
    deleteParametersSuccess: 'Parámetros eliminados con éxito',
    deleteParameterError: 'Error al eliminar parámetros',
    getTableTypeParameterError: 'Error al obtener los parámetros de la tabla',
    deleteLoaderParameters: 'Eliminando parámetros',

}