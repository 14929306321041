<template>
  <RouterView/>
</template>

<script setup>

import { useAuthStore } from '@/stores/authStore';
import { useRouter } from 'vue-router';

const router = useRouter();
const authStore = useAuthStore();

if (authStore.accessToken !== null) {

  if (authStore.scope?.id == undefined) {

    router.push({ name: 'ScopesSelectPage'})

  }

}

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
