export default {

    newAsset: 'Add Asset',
    relatedAssets: 'Related Assets',
    assets: 'Assets',
    assetCategory: 'Asset category',
    noAssetCategory: 'No Category',
    noAssetsCategories: 'No Asset Categories',

};
