import axios from 'axios';
import { useAuthStore } from '@/stores/authStore';
import router from '@/router';

const instance = axios.create({
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    },
    withCredentials: true,
});

const publicEndpoints = [
    '/countries',
    '/languages',
    '/states/{countryId}',
    '/cities/{stateId}',
    '/economic-activities',
    '/organization-categories',
    '/organization-types',
    '/plans',
];

instance.interceptors.request.use(

    (config) => {

        const authStore = useAuthStore();
        let token = authStore.accessToken;
        const subdomain = authStore.subdomain;
        const language = authStore.language;

        const scopeId = authStore.scope?.id;

        if (publicEndpoints.some(endpoint => config.url.includes(endpoint))) {

            token = authStore.clientAccessToken;

        }

        config.headers['X-language'] = 'es';

        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        if (authStore.baseUrl) {
            config.baseURL = authStore.baseUrl;
        }

        if (subdomain) {
            config.headers['X-Subdomain'] = subdomain;
        }

        if (language) {
            config.headers['X-Language'] = language;
        }

        if (scopeId) {
            config.headers['X-ScopeId'] = scopeId;
        }

        return config;
    },
    (error) => Promise.reject(error)
);

instance.interceptors.response.use(
    (response) => response,
    (error) => {

        if (error.response?.status === 403) {

            router.push({ name: 'InsufficientPermissionsPage' });

        }

        return Promise.reject(error);
    }
);

export const GET = (url, config = {}) => instance.get(url, config);
export const POST = (url, data = {}, config = {}) => instance.post(url, data, config);
export const PUT = (url, data = {}, config = {}) => instance.put(url, data, config);
export const DELETE = (url, config = {}) => instance.delete(url, config);

export default instance;
