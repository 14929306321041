export default {

    newScope: 'Nuevo Alcance',
    areasProcess: 'Áreas y procesos organizacionales ',
    noAreasProcesses: 'Procesos organizacionales no encontrados',
    noAreaProcess: 'Proceso organizacional no encontrado',
    ubication: 'Ubicación',
    interestedParties: 'Partes interesadas',
    notes: 'Notas',
    createScope: 'Crear Alcance',
    editScope: 'Editar Alcance',
    getScopeError: 'Error al obtener alcance',
    editScopeError: 'Ha un ocurrido un error al editar el alcance',
    createScopeError: 'Ha un ocurrido un error al crear el alcance',
    editScopeSuccess: 'Alcance editado exitosamente',
    createScopeSuccess: 'Alcance creado exitosamente',
    systemConfigurationPlaceHolder: 'Parametrización del sistema',
    interestedPartiesPlaceHolder: 'Partes interesadas... 0/500',
    notesPlaceHolder: 'Notas 0/500',
    descriptionPlaceHolder: 'Descripción 0/500',
    scopes: 'Alcances',
    scope: 'Alcance',
    status: 'Estado',
    getScopesError: 'Ha ocurrido un error al obtener los alcances: ',
    deleteScopeError: 'Ha ocurrido un error al eliminar el alcance: ',
    deleteScopeSuccess: 'Alcance eliminado exitosamente',
    scopeDetail: 'Ver detalle de alcance',
    editScopeLoader: 'Editando alcance...',
    createScopeLoader: 'Creando alcance...',
    deleteLoader: 'Eliminando alcance...',

};