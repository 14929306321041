export default {
    responsible: 'Responsible',
    control: 'Control',
    controlDomain: 'Control domain',
    treatmentAction: 'Treatment action',
    controlAction: 'Control action',
    controlsTitle: 'Controls of impact area',
    forAsset: 'For the asset',
    noControls: 'There are no controls associated with this impact area.',
    controlDescription: 'Description of the treatment action',
    followUpDate: 'Next follow up',
    creationDate: 'Creation date',

};
