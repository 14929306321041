import { defineStore } from 'pinia';
import { ref } from 'vue';
import axios from '@/axios';

export const useAuthStore = defineStore('auth', () => {

    const id = ref();
    const accessToken = ref(null);
    const clientAccessToken = ref(null);
    const subdomain = ref('app');
    const language = ref(null);
    const availableRoles = ref([]);
    const role = ref(null);
    const email = ref(null);
    const name = ref(null);
    const lastName = ref(null);
    const phone = ref(null);
    const hasMF2 = ref(null);
    const baseUrl = ref(process.env.VUE_APP_BASE_URL);
    const firstAccess = ref(false);
    const scope = ref({});

    const clientId = ref(process.env.VUE_APP_CLIENT_ID);
    const clientSecret = ref(process.env.VUE_APP_CLIENT_SECRET);

    function setAuthData(userId, token, newSubdomain, newLanguage, rolesData, newName, newLastName, newEmail, newPhone, newHasMF2) {

        id.value = userId;
        accessToken.value = token;
        subdomain.value = newSubdomain;
        language.value = newLanguage;
        availableRoles.value = Array.isArray(rolesData) ? rolesData : [];
        name.value = newName;
        lastName.value = newLastName;
        email.value = newEmail;
        role.value = null;
        phone.value = newPhone;
        hasMF2.value = newHasMF2;

    }

    function clearAuthData() {
        id.value = null;
        accessToken.value = null;
        clientAccessToken.value = null;
        subdomain.value = '';
        language.value = null;
        role.value = null;
        availableRoles.value = []
        name.value = null;
        lastName.value = null;
        email.value = null;
        scope.value = {};
        firstAccess.value = false;

    }

    function setScope(newScope) {
        scope.value = newScope;
    }

    function setFirstAccess(access) {

        firstAccess.value = access === "true";

    }

    function setEmail(newEmail) {
        email.value = newEmail;
    }

    function setRoleByCompany(companyRoleId) {

        if (!availableRoles.value.length) {

            role.value = {id:companyRoleId};
            return;

        }

        const selected = availableRoles.value.find((role) => role.id === companyRoleId);
        role.value = selected || null;
    }

    function clearEmail() {
        email.value = null;
    }

    /**
     * Obtiene un nuevo Client Credentials Token para los endpoints públicos.
     */
    async function fetchClientToken() {

        try {

            const params = {

                grant_type: 'client_credentials',
                client_id: clientId.value,
                client_secret: clientSecret.value,
                scope: ''
            }

            const response = await axios.post('oauth/token', params);

            clientAccessToken.value = response.data.access_token;
            return response.data;

        } catch (error) {

            console.error('Error obteniendo el token de cliente:', error);
            throw error;

        }
    }


    return {
        id,
        accessToken,
        clientAccessToken,
        subdomain,
        language,
        role,
        availableRoles,
        baseUrl,
        email,
        firstAccess,
        name,
        scope,
        phone,
        hasMF2,
        lastName,
        clientId,
        clientSecret,
        setAuthData,
        clearAuthData,
        setRoleByCompany,
        setEmail,
        setScope,
        clearEmail,
        setFirstAccess,
        fetchClientToken,
    };

}, {
    persist: {

        storage: localStorage,

    },
});
