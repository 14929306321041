export default {
    addCompany: 'Agregar organización',
    company: 'Organización',
    companies: 'Organizaciones',
    companyPlaceHolder: 'Selecciona una organización',
    noCompany: 'Organización no encontrada',
    noCompanies: 'Organizaciones no encontradas',
    legalName: 'Marca y Nombre legal',
    plan: 'Plan',
    planPlaceHolder: 'Selecciona un plan',
    noPlan: 'Plan no encontrado',
    noPlans: 'Planes no encontrados',
    brand: 'Marca',
    businessActivity: 'Actividad económica',
    activityPlaceHolder: 'Selecciona una actividad económica',
    noActivity: 'Actividad económica no encontrada',
    noActivities: 'Actividades económicas no encontradas',
    companiesError: 'Ha ocurrido un error al obtener las empresas: ',
    confirmDeleteCompany: '¿Está seguro que desea eliminar la organización?',
    deleteCompanyError: 'Ha ocurrido un error al eliminar la Organización',
    companyDeletedSuccess: 'Organización eliminada exitosamente',
    typeCompanyPlaceHolder: 'Selecciona un tipo de empresa',
    addOrCreateUser: 'Para continuar debes agregar al menos un usuario existente o crear uno nuevo.',
    unknown: 'Desconocido',
    companyQueryError: 'Ha ocurrido un error al consultar la Organización: ',
    companyCreatedSuccess: 'Empresa creada correctamente',
    companyCreationError: 'Ha ocurrido un error al crear la compañia: ',
    companyUpdatedSuccess: 'Empresa actualizada correctamente',
    companyUpdateError: 'Ha ocurrido un error al actualizar la empresa: ',
    companyCategory: 'Categoria de empresa',
    companyType: 'Tipo de empresa',
    companyCreate: 'Crear una organización',
    companySelect: 'Elige una organización',
    createCompany: 'Elige o crea una organización para comenzar',
};
