import { TRANSACTION_TYPES } from '../utils/constants';

const usersRoutes = [
    {
        path: '/users',
        name: 'UsersPage',
        component: () => import('../views/users/UsersComponent.vue'),
        meta: {
            requiresAuth: true,
            roles: ['SUPERADMIN'],
        },
    },
    {
        path: '/users/create',
        name: 'UserCreatePage',
        component: () => import('../views/users/UsersManagerComponent.vue'),
        props: { type: TRANSACTION_TYPES.CREATE },
        meta: {
            requiresAuth: true,
            roles: ['SUPERADMIN'],
        },
    },
    {
        path: '/users/edit/:userId',
        name: 'UsersEditPage',
        component: () => import('../views/users/UsersManagerComponent.vue'),
        props: route => ({ type: TRANSACTION_TYPES.EDIT, userId: route.params.userId }),
        meta: {
            requiresAuth: true,
            roles: ['SUPERADMIN'],
        },
    },
    {
        path: '/users/show/:userId',
        name: 'UsersShowPage',
        component: () => import('../views/users/UsersManagerComponent.vue'),
        props: route => ({ type: TRANSACTION_TYPES.SHOW, userId: route.params.userId }),
        meta: {
            requiresAuth: true,
            roles: ['SUPERADMIN'],
        },
    },
];

export default usersRoutes;