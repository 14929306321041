
const authRoutes = [

    {
        path: '/register',
        name: 'RegisterPage',
        component: () => import('../views/auth/RegisterComponent.vue'),
    },
    {
        path: '/login',
        name: 'LoginPage',
        component: () => import('../views/auth/LoginComponent.vue'),
    },
    {
        path: '/confirm-account',
        name: 'ConfirmAccountComponent',
        component: () => import('@/views/auth/ConfirmAccountComponent.vue')
    },
    {
        path: '/login/confirm-account',
        name: 'ConfirmAccountLoginComponent',
        component: () => import('@/views/auth/ConfirmAccountLoginComponent.vue')
    },
    {
        path: '/restore-password',
        name: 'RestorePasswordComponent',
        component: () => import('@/views/auth/RestorePasswordComponent.vue')
    },
    {
        path: '/change-password',
        name: 'ChangePasswordComponent',
        component: () => import('@/views/auth/ChangePasswordComponent.vue')
    },
    {
        path: '/reset-password/:token',
        name: 'ResetPasswordComponent',
        component: () => import('@/views/auth/ResetPasswordComponent.vue'),
        props: route => ({ token: route.params.token })
    },
    {
        path: '/account-confirmation/:token',
        name: 'AccountConfirmedComponent',
        component: () => import('@/views/auth/AccountConfirmedComponent.vue'),
        props: route => ({ token: route.params.token })
    },
    {
        path: '/confirm-invitation/:token',
        name: 'ConfirmInvitationComponent',
        component: () => import('@/views/auth/ConfirmInvitation.vue'),
        props: route => ({
            token: route.params.token,
            companyName: route.query.companyName,
            email: route.query.email,
            name: route.query.name,
            lastName: route.query.lastName,
        })
    },
    {
        path: '/complete-register',
        name: 'OAuthCallbackComponent',
        component: () => import('@/views/auth/OauthCallbackComponent.vue'),
    },
    {
        path: '/oauth/callback',
        name: 'OAuthCallback',
        component: () => import('@/views/auth/OAuthCallbackLoginComponent.vue')
    },
    {
        path: '/my-account',
        name: 'MyAccountPage',
        component: () => import('@/views/auth/MyAccountComponent.vue')
    }

];

export default authRoutes;