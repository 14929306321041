import { TRANSACTION_TYPES } from '../utils/constants';

const threatsRoutes = [

    {
        path: '/threats',
        name: 'ThreatsPage',
        component: () => import('../views/threats/ThreatsComponent.vue'),
        meta: { requiresAuth: true }

    },
    {
        path: '/threats/create',
        name: 'CreateThreatComponent',
        component: () => import('../views/threats/ThreatsManagerComponent.vue'),
        props: { type: TRANSACTION_TYPES.CREATE },
        meta: { requiresAuth: true }
    },
    {
        path: '/threats/edit/:threatId',
        name: 'EditThreatComponent',
        component: () => import('../views/threats/ThreatsManagerComponent.vue'),
        props: route => ({ type: TRANSACTION_TYPES.EDIT, threatId: route.params.threatId }),
        meta: { requiresAuth: true }

    },
    {
        path: '/threats/show/:threatId',
        name: 'ShowThreatComponent',
        component: () => import('../views/threats/ThreatsManagerComponent.vue'),
        props: route => ({ type: TRANSACTION_TYPES.SHOW, threatId: route.params.threatId }),
        meta: { requiresAuth: true }

    },

];

export default threatsRoutes;