export default {

    newScope: 'New Scope',
    areasProcess: 'Organizational Areas and Processes',
    noAreasProcesses: 'Organizational processes not found',
    noAreaProcess: 'Organizational process not found',
    ubication: 'Location',
    interestedParties: 'Interested Parties',
    notes: 'Notes',
    createScope: 'Create Scope',
    editScope: 'Edit Scope',
    getScopeError: 'Error retrieving scope',
    editScopeError: 'An error occurred while editing the scope',
    createScopeError: 'An error occurred while creating the scope',
    editScopeSuccess: 'Scope edited successfully',
    createScopeSuccess: 'Scope created successfully',
    systemConfigurationPlaceHolder: 'System Configuration',
    interestedPartiesPlaceHolder: 'Interested parties... 0/500',
    notesPlaceHolder: 'Notes 0/500',
    descriptionPlaceHolder: 'Description 0/500',
    scopes: 'Scopes',
    scope: 'Scope',
    status: 'Status',
    getScopesError: 'An error occurred while retrieving scopes: ',
    deleteScopeError: 'An error occurred while deleting the scope: ',
    deleteScopeSuccess: 'Scope deleted successfully',
    scopeDetail: 'View Scope Detail',
    editScopeLoader: 'Editing scope...',
    createScopeLoader: 'Creating scope...',
    deleteLoader: 'Deleting scope...',

};