export default {

    vulnerabilities: 'Vulnerabilidades',
    vulnerabilitiesMenu: 'Catálogo de vulnerabilidades',
    vulnerability: 'Vulnerabilidad',
    vulnerabilitiesOfAsset: 'Vulnerabilidades del activo',
    newVulnerability: 'Nueva Vulnerabilidad',
    getVulnerabilitysError: 'Ha ocurrido un error al obtener las vulnerabilidades',
    deleteVulnerabilityError: 'Ha ocurrido un error al eliminar la vulnerabilidad',
    deleteVulnerabilitySuccess: 'Vulnerabilidad eliminada exitosamente ',
    severity: 'Severidad',
    noSeverity: 'Severidad no encontrada',
    noSeverities: 'Severidades no encontradas',
    editVulnerabilityError: 'Ha ocuriddo un error al editar la vulnerabilidad',
    createVulnerabilityError: 'Ha ocuriddo un error al crear la vulnerabilidad',
    editVulnerabilitySuccess: 'Vulnerabilidad actualizada exitosamente',
    createVulnerabilitySuccess: 'Vulnerabilidad creada exitosamente',
    createVulnerability: 'Crear Vulnerabilidad',
    createLoaderVulnerability: 'Creando Vulnerabilidad',
    editLoaderVulnerability: 'Editando Vulnerabilidad',
    deleteLoaderVulnerability: 'Eliminando Vulnerabilidad',
    editVulnerability: 'Editar Vulnerabilidad',

};
