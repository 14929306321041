export default {
    responsible: 'Responsable',
    control: 'Control',
    controlDomain: 'Dominio de control',
    controlAction: 'Acción de control',
    treatmentAction: 'Acción de tratamiento',
    controlsTitle: 'Controles del factor de impacto',
    forAsset: 'Para el activo',
    noControls: 'No hay controles asociados a este factor de impacto.',
    controlDescription: 'Descripción de la acción de tratamiento',
    followUpDate: 'Próximo seguimiento',
    creationDate: 'Fecha de creación',

};
