export default {
    risksMenu: 'Cálculo del riesgo',
    riskMaps: 'Mapas de calor',
    risks: 'Riesgos',
    risksOfThreat: 'Riesgos de la amenaza',
    probability: 'Probabilidad',
    showAssetsRisk: 'Mostrar activos con riesgos',
    threatCategory: 'Categoría/Subcategoría',
    threatAsset: 'Amenaza/Activo',
    selectMeasure: 'Selecciona una valoración o calificación',
    selectProbability: 'Selecciona probabilidad',
    noFactorsMessage: 'aún no ha seleccionado los factores de impacto que aplican para este activo, Puedes dirigirte a la pestaña Criticidad del Activo para hacer este proceso.',
    noThreatsMessage: 'Este activo aún no tiene amenazas asociadas. Para realizar el cálculo de riesgo es necesario identificar las posibles amenazas que podrían afectar a este activo.',
    identify: 'Identificar',
    riskLevel: 'Nivel de riesgo',
    saveRisk: 'Guardar Riesgo',
    noRiskZone: 'No hay riesgos en esta zona',
    noRisksMessage: 'Aun no tienes riesgo calculados, si deseas calcular el riesgo de tus activos, puedes hacerlo en la sección de cálculo de riesgo.',

};
