const impactsRoutes = [
    {
        path: '/impacts',
        name: 'ImpactsPage',
        component: () => import('../views/impacts/ImpactsManagerComponent.vue'),
        props: { type: 'C' },
        meta: {
            requiresAuth: true,
            roles: ['ADMIN'],
        }

    }
];

export default impactsRoutes;