import { TRANSACTION_TYPES } from '../utils/constants';

const rolesRoutes = [
    {
        path: '/roles',
        name: 'RolesPage',
        component: () => import('../views/roles/RolesComponent.vue'),
        meta: {
            requiresAuth: true,
            roles: ['SUPERADMIN'],
        },
    },
    {
        path: '/roles/create',
        name: 'RolesCreatePage',
        component: () => import('../views/roles/RolesManagerComponent.vue'),
        props: { type: TRANSACTION_TYPES.CREATE },
        meta: {
            requiresAuth: true,
            roles: ['SUPERADMIN'],
        },
    },
    {
        path: '/roles/edit/:roleId',
        name: 'RolesEditPage',
        component: () => import('../views/roles/RolesManagerComponent.vue'),
        props: route => ({ type: TRANSACTION_TYPES.EDIT, roleId: route.params.roleId }),
        meta: {
            requiresAuth: true,
            roles: ['SUPERADMIN'],
        },
    },
    {
        path: '/roles/show/:roleId',
        name: 'RolesShowPage',
        component: () => import('../views/roles/RolesManagerComponent.vue'),
        props: route => ({ type: TRANSACTION_TYPES.SHOW, roleId: route.params.roleId }),
        meta: {
            requiresAuth: true,
            roles: ['SUPERADMIN'],
        },
    },

];

export default rolesRoutes;